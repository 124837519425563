


@font-face {
    font-family: "regular";
    src: url("../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@font-face {
    font-family: "bold";
    src: url("../components/fonts/Linotype_Brewery_W01_Bold.ttf");
}


@media screen and (max-width:600px) {
    .form-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .login-form{
        display: inline-block;
        margin-top: 50px;
        margin-bottom: 200px;
    }
    .sign-up-form{
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .login-dont-you-have-an-account{
        width: 100%;
        font-family: "regular";
        border: none;
        font-size: 11px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        color: black;
    }
    
    .login-form input{
        margin-top: 4%;
        margin-right: 4%;
        margin-left: 4%;
    
        width: 91%;
        border: none; 
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    
    .login-form input:focus{
        /* border: 1px solid #707070; */
    
        outline: none;
        border-color: #707070;
    }
    
    .sign-up-form input{
        margin:10px auto;
        margin-left: 6%;
        margin-right: 4%;
        width: 85%;
        border: none;
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    .account-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 10px;
        border:none;
    }
    
    .account-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "bold";
        letter-spacing: .97px;
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 13px;
        border:none;
    }
    
    .account-error{
        color: black;
        font-family: "regular";
        margin-left: 4%;
        margin-top: 4%;
        letter-spacing: 2px;
    }
    
    .account-info{
        font-family: "regular";
    
        letter-spacing: 2px;
    
        
    
    }
    
    
    
    
    .underline-styling {
        text-decoration: underline;
        margin-bottom: 25px;
        cursor: pointer;
    }
    

}

@media screen and (min-width:600px) and (max-width:960px) {

    .form-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .login-form{
        display: inline-block;
        margin-top: 50px;
        margin-bottom: 200px;
    }
    .sign-up-form{
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .login-dont-you-have-an-account{
        width: 100%;
        font-family: "regular";
        border: none;
        font-size: 13px;
        letter-spacing: 3px;
        margin-bottom: 30px;
        color: black;
    
    }
    
    .login-form input{
        margin-top: 4%;
        margin-right: 4%;
        margin-left: 4%;
    
        width: 91%;
        border: none; 
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    
    .login-form input:focus{
        /* border: 1px solid #707070; */
    
        outline: none;
        border-color: #707070;
    }
    
    .sign-up-form input{
        margin:10px;
        margin-right: 5%;
        margin-left: 16%;
        width: 65%;
        border: none;
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    .account-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 11px;
        border:none;
    }
    
    .account-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "bold";
        letter-spacing: .97px;
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 20px;
        border:none;
    }
    
    .account-error{
        color: black;
        font-family: "regular";
        margin-left: 4%;
        margin-top: 4%;
        letter-spacing: 2px;
    }
    
    .account-info{
        font-family: "regular";
    
        letter-spacing: 2px;
    
        
    
    }
    
    
    
    
    .underline-styling {
        text-decoration: underline;
        margin-bottom: 25px;
        cursor: pointer;
    }

}

@media screen and (min-width:960px) and (max-width:1280px) {

    .form-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .login-form{
        display: inline-block;
        margin-top: 50px;
        margin-bottom: 200px;
    }
    .sign-up-form{
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .login-dont-you-have-an-account{
        width: 100%;
        font-family: "regular";
        border: none;
        font-size: 13px;
        letter-spacing: 3px;
        margin-bottom: 30px;
        color: black;
    
    }
    
    .login-form input{
        margin-top: 4%;
        margin-right: 4%;
        margin-left: 4%;
    
        width: 91%;
        border: none; 
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    
    .login-form input:focus{
        /* border: 1px solid #707070; */
    
        outline: none;
        border-color: #707070;
    }
    
    .sign-up-form input{
        margin:10px;
        margin-left: 24%;
        width: 50%;
        border: none;
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 10px;
    }
    .account-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 11px;
        border:none;
    }
    
    .account-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "bold";
        letter-spacing: .97px;
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 20px;
        border:none;
    }
    
    .account-error{
        color: black;
        font-family: "regular";
        margin-left: 4%;
        margin-top: 4%;
        letter-spacing: 2px;
    }
    
    .account-info{
        font-family: "regular";
    
        letter-spacing: 2px;
    
        
    
    }
    
    
    
    
    .underline-styling {
        text-decoration: underline;
        margin-bottom: 25px;
        cursor: pointer;
    }
    

}

@media screen and (min-width:1280px)  {

    .form-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .login-form{
        display: inline-block;
        margin-top: 50px;
        margin-bottom: 200px;
    }
    .sign-up-form{
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .login-dont-you-have-an-account{
        width: 100%;
        font-family: "regular";
        border: none;
        font-size: 17px;
        letter-spacing: 3px;
        margin-bottom: 30px;
        color: black;
    
    }
    
    .login-form input{
        margin-top: 4%;
        margin-right: 4%;
        margin-left: 4%;
    
        width: 91%;
        border: none; 
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 12px;
    }
    
    .login-form input:focus{
        /* border: 1px solid #707070; */
    
        outline: none;
        border-color: #707070;
    }
    
    .sign-up-form input{
        margin:10px;
        margin-left: 24%;
        width: 50%;
        border: none;
        border-bottom:1px solid lightgrey;
        color: #696565;
        font-family: "regular";
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 11px;
    }
    .account-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 12px;
        border:none;
    }
    
    .account-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "bold";
        letter-spacing: .97px;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        border:none;
    }
    
    .account-error{
        color: black;
        font-family: "regular";
        margin-left: 4%;
        margin-top: 4%;
        letter-spacing: 2px;
    }
    
    .account-info{
        font-family: "regular";
    
        letter-spacing: 2px;
    
        
    
    }
    
    
    
    
    .underline-styling {
        text-decoration: underline;
        margin-bottom: 25px;
        cursor: pointer;
    }
    

}



