@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

    @media screen and (max-width:600px){
        .shopping-bag-container
{
            font-family: "regular";
            letter-spacing: 1.4px;
            line-height: 14px;
            font-size: 10px;
            margin: 5%;
            
        }

        .shopping-bag-basket-image{
            width: 100%;
        }

        .shopping-bag-add{
            font-size: 9px;
            display: inline-block;
            border: none;
            color: black;
        }
        .shopping-bag-remove{
            display: inline-block;
            border: none;
            color: black;
            margin-left: -5px;
        }
        .shopping-bag-item-qty{
            display: inline-block;
        }

        .shopping-bag-item-details{
            width: 100%;
            margin-left: 13%;
        }

        .shopping-bag-sum-black-button{
            width: 100%;
            margin-top: 10px;
            height: 31px;
            background: black;
            color: white;
            font-family: "regular";
            letter-spacing: 1.8px;
            font-size: 9px;
            border: none;
        }
        

        .shopping-bag-sum-grey-button{
            width: 100%;
            margin-top: 10px;
            height: 30px;
            border:none;
            background: #E6E4DC;
            color: black;
            font-family: "bold";
            letter-spacing: 1.3px;
            font-size: 9px;
            text-align: center;
        }

        .shopping-bag-sum-grey-button:focus{
           outline: none;
        }

        .shopping-bag-checkout-button{
            width: 100%;
            margin-top: -30px;
            height: 30px;
            background: black;
            color: white;
            font-family: "regular";
            letter-spacing: 1.8px;
            border: none;

        }

        .shopping-bag-sum-container{
            margin-top: -30px;
            font-family: "regular";
            line-height: 20px;
            font-size: 9px;
            letter-spacing: 1.7px;
            margin-bottom: 20px;
            
        }

        .cart-is-empty{
            width: 70%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 10px;
            line-height: 20px;
            letter-spacing: 1.5px;
            margin-top: 50px;
            margin-bottom: 20px;
            
        }

        .cart-is-empty-image-container{
            width: 90%;
            text-align: center;
            margin: 0 auto;
            display: flex; /* set the display property to flex */
            justify-content: space-between; /* distribute the boxes evenly along the horizontal axis */
        }

        .cart-empty-image-1 {
            width: 50%;
            height: 100%;
        }

        .cart-empty-image-2 {
            width: 20%;
            height: 30%;
            position: relative;
            right: 30px;
            top:10px;
        }

        .cart-empty-image-3 {
            width: 29%; 
            height: 100%;
            transform: rotate(20deg);
            position: relative;
            top:40px;
            right: 20px;
        }

        .shopping-bag-carpi {
            width: 100%;
        }

        .shopping-bag-price{
            text-align: right;
        }
    }
    @media screen and (min-width:600px) and (max-width:960px){
        .shopping-bag-container{
            font-family: "regular";
            letter-spacing: 1px;
            line-height: 16px;
            font-size: 9px;
            margin: 25px auto;
        }

        /* Left side : SHOPPING BAG SUM*/
        .shopping-bag-basket-image{
            width: 100%;
        }

        .shopping-bag-add{
            display: inline-block;
            border: none;
            color:black;
        }
        .shopping-bag-remove{
            display: inline-block;
            border: none;
            color:black;
        }
        .shopping-bag-item-qty{
            display: inline-block;
        }


        /* Right side : SHOPPING BAG SUM*/

        .shopping-bag-sum-container{
            font-family: "regular";
            line-height: 25px;
            font-size: 9px;
            
        }
        .shopping-bag-sum-small-space{
            height: 20px;
        }

        .shopping-bag-sum-black-button{
            width: 100%;
            height: 25px;
            background: black;
            color: white;
            border: none;
            font-family: "regular";
            letter-spacing: 1.3px;
            font-size: 8.5px;
        }

        .shopping-bag-sum-grey-button{
            width: 100%;
            height: 23px;
            border:none;
            background: #E6E4DC;
            color: black;
            font-family: "bold";
            letter-spacing: 1.3px;
            font-size: 8.5px;
            text-align: center;
        }

        .shopping-bag-sum-grey-button:focus{
           outline: none;
        }


        



        /* CART IS EMPTY DESIGN*/
        .cart-is-empty{
            width: 30%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 1.4px;
            margin-top: 70px;
            margin-bottom: 10px;
            
        }

        .cart-is-empty-image-container{
            width: 70%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 2px;
            display: flex; /* set the display property to flex */
            justify-content: space-between; /* distribute the boxes evenly along the horizontal axis */
        }

        .cart-empty-image-1 {
            width: 50%;
            height: 100%;
        }

        .cart-empty-image-2 {
            width: 25%;
            height: 30%;
            position: relative;
            right: 95px;
            top:50px;
        }

        .cart-empty-image-3 {
            width: 29%; 
            height: 100%;
            transform: rotate(20deg);
            position: relative;
            top:120px;
            right: 70px;
        }
        .shopping-bag-carpi {
            width: 100%;
            cursor: pointer;
        }
    }
    @media screen and (min-width:960px) and (max-width:1280px){

        .shopping-bag-container{
            font-family: "regular";
            letter-spacing: .97px;
            line-height: 21px;
            font-size: 10px;
            margin: 25px auto;
        }

        /* Left side : SHOPPING BAG SUM*/
        .shopping-bag-basket-image{
            width: 100%;
        }

        .shopping-bag-add{
            display: inline-block;
            border: none;
        }
        .shopping-bag-remove{
            display: inline-block;
            border: none;
        }
        .shopping-bag-item-qty{
            display: inline-block;
        }


        /* Right side : SHOPPING BAG SUM*/

        .shopping-bag-sum-container{
            font-family: "regular";
            line-height: 35px;
            font-size: 10px;
            margin-top:-7px;
            
        }
        .shopping-bag-sum-small-space{
            height: 20px;
        }

        .shopping-bag-sum-black-button{
            width: 100%;
            height: 30px;
            background: black;
            color: white;
            border: none;
            font-family: "regular";
            letter-spacing: 1.7px;
            font-size: 8.5px;
            font-weight: bold;
        }
        
        .shopping-bag-sum-grey-button{
            width: 100%;
            height: 29px;
            border:none;
            background: #E6E4DC;
            color: black;
            font-family: "bold";
            letter-spacing: 1.3px;
            font-size: 8.5px;
            text-align: center;
        }

        .shopping-bag-sum-grey-button:focus{
           outline: none;
        }




        /* CART IS EMPTY DESIGN*/
        .cart-is-empty{
            width: 30%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 1.4px;
            margin-top: 70px;
            margin-bottom: 10px;
            
        }

        .cart-is-empty-image-container{
            width: 70%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 2px;
            display: flex; /* set the display property to flex */
            justify-content: space-between; /* distribute the boxes evenly along the horizontal axis */
        }

        .cart-empty-image-1 {
            width: 50%;
            height: 100%;
        }

        .cart-empty-image-2 {
            width: 25%;
            height: 30%;
            position: relative;
            right: 95px;
            top:50px;
        }

        .cart-empty-image-3 {
            width: 29%; 
            height: 100%;
            transform: rotate(20deg);
            position: relative;
            top:120px;
            right: 70px;
        } 
        .shopping-bag-carpi {
            width: 100%;
        }
    
    }
    @media screen and (min-width:1280px) {
        .shopping-bag-container{
    
            font-family: "regular";
            letter-spacing: .97px;
            line-height: 21px;
            font-size: 14px;
            margin: auto;
        }
        .shopping-bag-basket-image{
            width: 100%;
            height: 100%;
        }

        .shopping-bag-add{
            display: inline-block;
            border: none;
        }
        .shopping-bag-remove{
            display: inline-block;
            border: none;
        }
        .shopping-bag-item-qty{
            display: inline-block;
        }

        .shopping-bag-sum-container{
            font-family: "regular";
            line-height: 35px;
            font-size: 12px;
            
        }
        .shopping-bag-sum-small-space{
            height: 20px;
        }
        
        .shopping-bag-sum-black-button{
            width: 100%;
            height: 40px;
            background: black;
            color: white;
            font-family: "regular";
            letter-spacing: .97px;
            font-size: 12px;
            border: none;
        }
        
        .shopping-bag-sum-grey-button{
            width: 100%;
            height: 37.9px;
            border:none;
            background: #E6E4DC;
            color: black;
            font-family: "bold";
            letter-spacing: 1.3px;
            font-size: 12px;
            text-align: center;
        }

        .shopping-bag-sum-grey-button:focus{
           outline: none;
        }
        .cart-is-empty{
            width: 30%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 2px;
            margin-top: 50px;
            margin-bottom: 100px;
            
        }

        .cart-is-empty-image-container{
            width: 70%;
            text-align: center;
            margin: 0 auto;
            font-family: "regular";
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 2px;
            display: flex; /* set the display property to flex */
            justify-content: space-between; /* distribute the boxes evenly along the horizontal axis */
        }

        .cart-empty-image-1 {
            width: 50%;
            height: 100%;
        }

        .cart-empty-image-2 {
            width: 20%;
            height: 30%;
            position: relative;
            right: 120px;
            top:50px;
        }

        .cart-empty-image-3 {
            width: 29%; 
            height: 100%;
            transform: rotate(20deg);
            position: relative;
            top:150px;
            right: 90px;
        }
        .shopping-bag-carpi {
            width: 100%;
        }
    }