


@media screen and (max-width:600px){
    .policy-text{
        width: 90%;
        margin: auto;
        font-family: "regular";
        font-size: 10px;
    }

}
@media screen and (min-width:600px) and (max-width:960px){
    .policy-text{
        width: 90%;
        margin: auto;
        font-family: "regular";
        font-size: 10px;
    }

}
@media screen and (min-width:960px) and (max-width:1280px){
    .policy-text{
        width: 80%;
        margin: auto;
        padding: 50px;
        font-family: "regular";
        font-size: 13px;
    }

}
@media screen and (min-width:1280px) and (max-width:1400px){
    .policy-text{
        width: 80%;
        margin: auto;
        padding: 50px;
        font-family: "regular";
    }
}
@media screen and (min-width:1400px) and (max-width:1800px){
    .policy-text{
        width: 80%;
        margin: auto;
        padding: 50px;
        font-family: "regular";
    }
}

@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}