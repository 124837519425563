@font-face {
    font-family: "regular";
    src: url("../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){
    .logged-in-form-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "regular";
    }
    
    .logged-in-form{
        display: inline-block;
        width: 80%;
        letter-spacing: 1px;
        margin-top: 50px;
    }
    
    .logged-in-form input{
        width: 100% ;
        margin-top: 30px;

        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 0.1px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 11px;
        letter-spacing: 1.2px;

    }
    
    
    .logged-in-welcome-msg{
        font-size: 13px;
    
        text-align: center;
        margin-bottom: 50px;
    }
    
    .logged-in-account-info{
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 11px;
    }
    
    
    
    .logged-in-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 10px;
        border:none;
    }
}

@media screen and (min-width:600px) and (max-width:960px){
    .logged-in-form-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "regular";
    }
    
    .logged-in-form{
        display: inline-block;
        width: 50%;
        letter-spacing: 1px;
        margin-top: 50px;
    }
    
    .logged-in-form input{
        width: 100% ;
        margin-top: 30px;

        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 0.1px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 11px;
        letter-spacing: 1.2px;

    }
    
    
    .logged-in-welcome-msg{
        font-size: 13px;
    
        text-align: center;
        margin-bottom: 30px;
    }
    
    .logged-in-account-info{
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 11px;
    }
    
    
    
    .logged-in-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 10px;
        border:none;
    }
}

@media screen and (min-width:960px) and (max-width:1280px){
    .logged-in-form-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "regular";
    }
    
    .logged-in-form{
        display: inline-block;
        width: 40%;
        letter-spacing: 1px;
        margin-top: 50px;
    }
    
    .logged-in-form input{
        width: 100% ;

        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 0.1px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.2px;

    }
    
    
    .logged-in-welcome-msg{
        font-size: 13px;
    
        text-align: center;
        margin-bottom: 30px;
    }
    
    .logged-in-account-info{
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 11px;
    }
    
    
    
    .logged-in-black-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 2px;
        font-size: 11px;
        border:none;
    }
}

@media screen and (min-width:1280px) {
    .logged-in-form-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: regular;
    }
    
    .logged-in-form{
        display: inline-block;
        width: 40%;
        letter-spacing: 4px;
        padding-top: 100px;
    }
    
    .logged-in-form input{
        width: 100% ;
        margin-top: 50px;
    }
    
    .logged-in-divider {
        background: black;
    }
    
    .logged-in-welcome-msg{
        font-size: 18px;
    
        text-align: center;
        margin-bottom: 100px;
    }
    
    .logged-in-account-info{
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 13px;
    }
    
    
    
    .logged-in-black-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 13px;
        border:none;
    }
}


