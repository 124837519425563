.edit-product-container{
   width: 100%;
   font-size: 10px;
   margin: 10px ;
   height: 300px;
   
}

    
           
.edit-product-image-small{
   width: 100%;
}