.upload-financial-values-form {
               max-width: 400px;
               margin:100px auto;
               text-align: center;
           }
           
           .upload-financial-values-form label {
               text-align: left;
               display: block;
           }
           
           .upload-financial-values-form h2 {
               font-size:20px;
               margin-bottom: 30px;
           }
           
           .upload-financial-values-form input, .upload-financial-values-form textarea {
               width: 100%;
               padding: 6px 10px;
               margin:10px 0;
               border:1px solid black;
               box-sizing: border-box;
               display: block;
           }
           
           .upload-financial-values-form button {
               background:black;
               color: white;
               border: 0;
               padding: 8px;
               cursor: pointer;
           }
           
           .remove-image-edit-page{
               position: relative;
               bottom: 300px;
               left:0px;
           }
           