@font-face {
    font-family: "regular";
    src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}


.shipping-shopping-bag-remove{
    display: inline-block;
    border: none;
    color: black;
}

.shipping-shopping-bag-item-qty{
    display: inline-block;
}

.shipping-shopping-bag-add{
    font-size: 9px;
    display: inline-block;
    border: none;
    color: black;
}

.shipping-basket-image{
    width: 100%;
}




@media screen and (max-width:600px){

    .shipping-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
        margin-top: 25px;
    
    }
    
    
    .shipping-info-container{
        font-family: "regular";
        letter-spacing: .97px;
        line-height: 21px;
        font-size: 10px;
        margin: 5%;
    }

    .shipping-shopping-bag-container{
        margin-top: -30px;
    }

    .shipping-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 9px;
    
    }
    
    .shipping-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 9px;
    
    }
    
    .shipping-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 9px;
    
    }
    
    .shipping-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 9px;
    }

    .shipping-grey-button{
        width: 100%;
        height: 40px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
    }

    .shipping-black-continue-to-payment-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
        border: none;
    }
    
    .payment-card-image{
        width: 100%;
    }
    
    .change-button {
        border:none;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
        color: black;
    
    }

    .shipping-shopping-bag-item-details{
        width: 50%;
        height: 30px;
        margin-left: 10px;
        font-size: 10px;
        white-space: nowrap;
        line-height: 15px;
        letter-spacing: 1.3px;
    }

    .shipping-carpi{
        width: 100%;
    }

}
@media screen and (min-width:600px) and (max-width:960px){

    .shipping-info-container{

        font-family: "regular";
        letter-spacing: 1px;
        line-height: 18px;
        font-size: 11.3px;
        margin: 20px auto;
    }

    .shipping-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
        margin-top:25px
    
    }
    
    .payment-card-image{
        width: 100%;
    }
    .shipping-black-continue-to-payment-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 1.2px;
        font-size: 10px;
        border: none;
    }

    .shipping-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "bold";
        letter-spacing: 1.2px;
        font-size: 10px;
    }
    
    .change-button {
        border:none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 10px;
    
    }
    .shipping-shopping-bag-container{
        margin-top: 25px;
    }

    .shipping-shopping-bag-item-details{
        width: 90%;
        margin-left: 13%;
        font-size: 10px;
    }

    .shipping-changable-enabled-email-input{
        width: 100%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    }

    .shipping-price {

        font-size: 10px;
    }

    .shipping-carpi{
        width: 100%;
    }


}
@media screen and (min-width:960px) and (max-width:1280px){

    .shipping-info-container{

        font-family: "regular";
        letter-spacing: 1px;
        line-height: 18px;
        font-size: 11.3px;
        margin: 25px auto;
    }

    .shipping-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
        margin-top:30px
    
    }
    .shipping-shopping-bag-container{
        margin-top: 30px;
    }
    
    .payment-card-image{
        width: 100%;
    }
    .shipping-black-continue-to-payment-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.2px;
        font-size:8px;
        border: none;
    }

    .shipping-grey-button{
        width: 100%;
        height: 30px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.2px;
        font-size: 8px;
    }
    
    .change-button {
        border:none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 10px;
    
    }

    .shipping-shopping-bag-item-details{
        width: 90%;
        margin-left: 13%;
        font-size: 10px;
    }

    .shipping-changable-enabled-email-input{
        width: 100%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .shipping-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    }

    .shipping-price {

        font-size: 10px;
    }

    .shipping-carpi{
        width: 100%;
    }

 
    

}
@media screen and (min-width:1280px) {

    .shipping-info-container{

        margin-left: 3.65%;
        font-family: "regular";
        letter-spacing: 1.5px;
        line-height: 21px;
        font-size: 13px;
    }

    .shipping-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 13px;
        margin-top: 30px;
    
    }
    
    
    .payment-card-image{
        width: 100%;
    }
    .shipping-black-continue-to-payment-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
        border: none;
    }
    
    .change-button {
        border:none;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 12px;
    
    }

    .shipping-shopping-bag-item-details{
        width: 90%;
        margin-left: 13%;
        font-size: 13px;
        letter-spacing: 1.5px;
    }

    .shipping-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .shipping-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .shipping-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .shipping-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    }

    .shipping-carpi{
        width: 100%;
    }

    .shipping-grey-button{
        width: 100%;
        height: 40px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 12px;
    }

    .shipping-shopping-bag-container{
        margin-top: 30px;
    }
    

}