
@media screen and (max-width:600px){



    .shop-title-filter{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 15px;
        color: black;
        padding: 0;
    }
    
    .shop-title-sort{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 15px;
        color: black;
        padding: 0;

    }

    .mobile-shop-filter-container{
        font-family: "regular";
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1px;
        z-index: 99999999999999;

    }
    
    .shop-filter-container{
        display: none;
    }

    .shop-filter-small-title{
        font-family: "bold";
        font-size: 13px;
    }

    .material-filter-names{
        border: none;
        width: 100%;
        text-align: left;
        font-family: "regular";
        color: black;
        letter-spacing: 1.2px;
        padding: 0;
        font-size: 10px;
    }

    .color-filter-names{
        border: none;
        font-family: "regular";
        color: black;
        font-size: 10px;
        letter-spacing: 1.2px;
    }

    
    .shop-sort-container{
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1px;
        text-align: right;
        font-family: "regular";
        z-index: 500;
    }
    
    .sort-filter-button{
        font-family: "regular";
        letter-spacing: 1px;
        color: black;
        border: none;
        font-size: 10.5px;
        letter-spacing: 1.2px;
    }
    
    
}
@media screen and (min-width:600px) and (max-width:960px){
    .shop-title-filter{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
        color: black;
        padding: 0;
    }
    
    .shop-title-sort{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
        color: black;
        padding: 0;
    }

    .mobile-shop-filter-container{
        display: none;
    }



    
    .shop-filter-container{
        font-family: "regular";
        line-height: 30px;
        z-index: 99999999999999;
    }

    .shop-filter-small-title{
        font-family: "bold";
        font-size: 11px;
        letter-spacing: 1px;
    }


    .material-filter-names{
        border: none;
        width: 100%;
        text-align: left;
        font-family: "regular";
        padding: 0;
        color: black;
        font-size: 10px;
        letter-spacing: 1.2px;
    }

    .color-filter-names{
        border: none;
        color: black;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 1.2px;
    }





    
    .shop-sort-container{
        line-height: 30px;
        letter-spacing: 1px;
        text-align: right;
        font-family: "regular";
        z-index: 500;
    }
    
    .sort-filter-button{
        font-family: "regular";
        font-size: 11px;
        letter-spacing: 1.2px;
        color: black;
        border: none;
    }
    
    
    

}
@media screen and (min-width:960px) and (max-width:1280px){

    .shop-title-filter{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 15px;
        color: black;
        padding: 0;
    }
    
    .shop-title-sort{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 15px;
        color: black;
        padding: 0;
    }

    .mobile-shop-filter-container{
        display: none;
    }
    
    .shop-filter-container{
        font-family: "regular";
        line-height: 30px;
        z-index: 99999999999999;
    }

    .shop-filter-container.open{
        font-family: "regular";
        line-height: 30px;

        transform: translateY(0);
        z-index: 99999999999999;
    }

    .slide-content.open {
        transform: translateY(0);
      }

    .shop-filter-small-title{
        font-family: "bold";
        font-size: 11px;
        letter-spacing: 1px;
    }

    .material-filter-names{
        border: none;
        width: 100%;
        text-align: left;
        font-family: "regular";
        padding: 0;
        color: black;
        font-size: 11px;
        letter-spacing: 1.2px;
    }

    .color-filter-names{
        border: none;
        color: black;
        font-family: "regular";
        font-size: 11px;
        letter-spacing: 1.2px;
    }





    
    .shop-sort-container{
        line-height: 30px;
        letter-spacing: 1px;
        text-align: right;
        font-family: "regular";
        z-index: 500;
    }
    
    .sort-filter-button{
        font-family: "regular";
        font-size: 11px;
        letter-spacing: 1.2px;
        color: black;
        border: none;
    }
    
    

}
@media screen and (min-width:1280px){

    .shop-title-filter{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 16px;
        color: black;
        padding: 0;
    }
    
    .shop-title-sort{
        border: none;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 16px;
        color: black;
        padding: 0;
    }

    .mobile-shop-filter-container{
        display: none;
    }



    
    .shop-filter-container{
        font-family: "regular";
        line-height: 30px;
        z-index: 99999999999999;
    }

    .shop-filter-small-title{
        font-family: "bold";
        font-size: 12px;
        letter-spacing: 1px;
    }


    .material-filter-names{
        border: none;
        width: 100%;
        text-align: left;
        font-family: "regular";
        padding: 0;
        color: black;
        font-size: 12px;
        letter-spacing: 1.2px;
    }

    .color-filter-names{
        border: none;
        color: black;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.2px;
    }





    
    .shop-sort-container{
        line-height: 30px;
        letter-spacing: 1px;
        text-align: right;
        font-family: "regular";
        z-index: 500;
    }
    
    .sort-filter-button{
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.2px;
        color: black;
        border: none;
    }
    
    
}

@font-face {
    font-family: "regular";
    src: url("../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@font-face {
    font-family: "bold";
    src: url("../components/fonts/Linotype_Brewery_W01_Bold.ttf");
}