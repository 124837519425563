@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        display: none;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        width: 100%;
    }


    .search-bar{
        height: 20px;
        margin-bottom: 10px;
        width: 92%;
        margin: auto;
        margin-bottom: 10px;
    
    }
    .search-bar input {
        width: 100%;
        border: none;
        height: 80%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 10px;
    }

    .search-bar input:focus {
        outline: none;
    }
    .search-bar div {
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 85%;
        height: 200px;
      }

      .pop-up-close-icon{

        font-size:16px;
        color:"grey";

      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.1px;
        font-size: 8px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 40px;
      }

      .popup-img{
        width: 50%; 
        height: 200px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        letter-spacing: 1.9px;
        font-size: 8px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 20px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 8px;
        border: none;
        margin-top: 10px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:65%;
        left:11%;
        height: 30%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:180%;
        right:14%;
        height: 30%;
        width: 30%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

}


@media screen and (min-width:600px) and (max-width:768px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        display: none;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        width: 100%;
    }
    .xs-homepage{
        display: none;
    }

    .search-bar{
        height: 20px;
        margin-bottom: 10px;
        width: 92%;
        margin: auto;
        margin-bottom: 10px;
    
    }
    .search-bar input {
        width: 100%;
        border: none;
        height: 80%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 10px;
    }

    .search-bar input:focus {
        outline: none;
    }
    .search-bar div {
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 80%;
        height: 250px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 11px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 60px;
      }

      .popup-img{
        width: 50%; 
        height: 250px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;


        letter-spacing: 1.9px;
        font-size: 10px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 20px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 10px;
        border: none;
        margin-top: 10px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:100%;
        left:11%;
        height: 50%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:350%;
        right:11%;
        height: 60%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }
}

@media screen and (min-width:768px) and (max-width:960px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        display: none;
    }
    .m-homepage{
        width: 100%;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }

    .search-bar{
        height: 20px;
        width: 92%;
        margin: auto;
        margin-bottom: 10px;
    
    }
    .search-bar input {
        width: 100%;
        border: none;
        height: 80%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 12px;
    }

    .search-bar input:focus {
        outline: none;
    }
    .search-bar div {
        height: 100%;
    }
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 60%;
        height: 350px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 13px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 90px;
        margin-top: 20px;
      }

      .popup-img{
        width: 50%; 
        height: 350px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        padding-bottom: 5px;


        letter-spacing: 1.9px;
        font-size: 11px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 11px;
        border: none;
        margin-top: 50px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .pop-up-close-icon{

        font-size:20px;
        color:grey;

      }
      .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:130%;
        left:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:2700px;
        right:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }
}

@media screen and (min-width:960px) and (max-width:1280px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        width: 100%;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }

    .search-bar{
        height: 20px;
        margin-bottom: 10px;
        width: 92%;
        margin: auto;
        margin-bottom: 10px;
    
    }
    .search-bar input {
        width: 100%;
        border: none;
        height: 80%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 13px;
    }

    .search-bar input:focus {
        outline: none;
    }
    .search-bar div {
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 60%;
        height: 350px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 15px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 90px;
        margin-top: 20px;
      }

      .popup-img{
        width: 50%; 
        height: 350px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        padding-bottom: 5px;


        letter-spacing: 1.9px;
        font-size: 13px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 13px;
        border: none;
        margin-top: 50px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .pop-up-close-icon{

        font-size:20px;
        color:grey;

      }

      .my-clickable{
        background-color:rgba(52, 52, 52, 0);
        width: 100px;
    
        position:absolute;
        transform: rotate(5deg);
        top:18.3%;
        left:11%;
        height: 7%;
        width: 28%;
        z-index: 99999999999;
        cursor: pointer;
    
    }
    
    
    .my-clickable-second{
        background-color:rgba(52, 52, 52, 0);
    
        position:absolute;
        transform: rotate(5deg);
        top:65.5%;
        right:11%;
        height: 7%;
        width: 28%;
        z-index: 999999999;
        cursor: pointer;
    
    }

    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:160%;
        left:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;

        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:3000px;
        right:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

      
      
}


@media screen and (min-width:1280px) and (max-width:1400px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        width: 100%;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }


    .search-bar{
        width: 93%;
        height: 30px;
        margin-bottom: 10px;
        margin-left: 3.4%;
        margin-right: 3.4%;

    
    }
    .search-bar input {
        width: 100%;
        height: 90%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 16px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: none;
    }
    .search-bar div {
        width: 100%;
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 60%;
        height: 400px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 17px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 150px;
        margin-top: 20px;
      }

      .popup-img{
        width: 50%; 
        height: 400px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        padding-bottom: 5px;


        letter-spacing: 1.9px;
        font-size: 15px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 14px;
        border: none;
        margin-top: 50px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .pop-up-close-icon{

        font-size:27px;
        color:grey;

      }

      .my-clickable{
        background-color:red;
    
        position:absolute;
        transform: rotate(5deg);
        top:18.3%;
        left:11%;
        height: 7%;
        width: 28%;
        z-index: 9999999999999;
        cursor: pointer;
    
    }
    
    
    .my-clickable-second{
        background-color:rgba(52, 52, 52, 0);
    
        position:absolute;
        transform: rotate(5deg);
        top:65.5%;
        right:11%;
        height: 7%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
    
    }


    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:160%;
        left:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;

        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:3800px;
        right:11%;
        height: 70%;
        width: 28%;
        z-index: 999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }


}

@media screen and (min-width:1400px) and (max-width:1700px){
    .xl-homepage{
        display: none;
    }
    .l-homepage{
        width: 100%;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }


    .search-bar{
        width: 93%;
        height: 30px;
        margin-bottom: 10px;
        margin-left: 3.4%;
        margin-right: 3.4%;

    
    }
    .search-bar input {
        width: 100%;
        height: 90%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 16px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: none;
    }
    .search-bar div {
        width: 100%;
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 60%;
        height: 400px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 17px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 150px;
        margin-top: 20px;
      }

      .popup-img{
        width: 50%; 
        height: 400px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        padding-bottom: 5px;


        letter-spacing: 1.9px;
        font-size: 15px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 14px;
        border: none;
        margin-top: 50px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .pop-up-close-icon{

        font-size:27px;
        color:grey;

      }

      .my-clickable{
        background-color:red;
    
        position:absolute;
        transform: rotate(5deg);
        top:18.3%;
        left:11%;
        height: 7%;
        width: 28%;
        z-index: 9999999999999;
        cursor: pointer;
    
    }
    
    
    .my-clickable-second{
        background-color:rgba(52, 52, 52, 0);
    
        position:absolute;
        transform: rotate(5deg);
        top:65.5%;
        right:11%;
        height: 7%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
    
    }


    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:160%;
        left:11%;
        height: 70%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:560%;
        right:11%;
        height: 90%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }


}

@media screen and (min-width:1700px) and (max-width:1920px){
    .xl-homepage{
        width: 100%;
    }
    .l-homepage{
        display: none;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }


    .search-bar{
        width: 93%;
        height: 30px;
        margin-bottom: 10px;
        margin-left: 3.4%;
        margin-right: 3.4%;

    
    }
    .search-bar input {
        width: 100%;
        height: 90%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 16px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: none;
    }
    .search-bar div {
        width: 100%;
        height: 100%;
    }

    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        width: 60%;
        height: 400px;
      }

      .popup-text {
        float: left;
        width: 50%;
        padding: 15px;
        box-sizing: border-box;
        letter-spacing: 1.9px;
        font-size: 17px;
        line-height: 20px;
        height: 100%;

      }

      .popup p {
        font-family: 'regular';
        margin-bottom: 150px;
        margin-top: 20px;
      }

      .popup-img{
        width: 50%; 
        height: 400px;
        object-fit: cover;
        float: right;
      }


      .popup-input {
        font-family: 'regular';
        width: 88%;
        border: none;
        border-bottom: 0.1px solid grey;
        padding-bottom: 5px;


        letter-spacing: 1.9px;
        font-size: 15px;
        line-height: 20px;
      }

      .popup-input:focus {
        outline: none;
        background: #FAFAF6;
      }

      .popup button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2.1px;
        font-size: 14px;
        border: none;
        margin-top: 50px;

    }

    .home-page-container-blur {

        filter: blur(7000000px);

    } 

    .pop-up-close-icon{

        font-size:27px;
        color:grey;

      }

      .my-clickable{
        background-color:red;
    
        position:absolute;
        transform: rotate(5deg);
        top:18.3%;
        left:11%;
        height: 7%;
        width: 28%;
        z-index: 9999999999999;
        cursor: pointer;
    
    }
    
    
    .my-clickable-second{
        background-color:rgba(52, 52, 52, 0);
    
        position:absolute;
        transform: rotate(5deg);
        top:65.5%;
        right:11%;
        height: 7%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
    
    }


    .first-clickable{
        background-color: red;
        position:absolute;
        transform: rotate(5deg);
        top:160%;
        left:11%;
        height: 70%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }

    .second-clickable{
        background-color: green;
        position:absolute;
        transform: rotate(5deg);
        top:620%;
        right:11%;
        height: 70%;
        width: 28%;
        z-index: 999999;
        cursor: pointer;
        background-color:rgba(52, 52, 52, 0);
    }


}


@media screen and (min-width:1920px) {
    .xl-homepage{
        width: 100%;
    }
    .l-homepage{
        display: none;
    }
    .m-homepage{
        display: none;
    }
    .s-homepage{
        display: none;
    }
    .xs-homepage{
        display: none;
    }

    .search-bar input {
        width: 100%;
        border: none;
        height: 90%;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 16px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: none;
    }
    .search-bar div {
        width: 100%;
        height: 100%;
    }
}





.homepage-container{
    margin-bottom: -150px;
    position: relative;
    width: 100%;
}

.my-clickable{
    background-color:rgba(52, 52, 52, 0);

    position:absolute;
    transform: rotate(5deg);
    top:18.3%;
    left:11%;
    height: 7%;
    width: 28%;
    z-index: 999999;
    cursor: pointer;

}


.my-clickable-second{
    background-color:rgba(52, 52, 52, 0);

    position:absolute;
    transform: rotate(5deg);
    top:65.5%;
    right:11%;
    height: 7%;
    width: 28%;
    z-index: 999999;
    cursor: pointer;

}














