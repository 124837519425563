
@media screen and (max-width:600px){
               .send-order-info-mail{
               width: 100%;
               height: 500px;
               margin: 50px auto;
               }

               .send-order-info-mail input{
               height: 30px;
               width: 90%;
               margin: 10px auto;
               display: block;
               border-width: 0.1px;
               }

               .send-order-info-mail button{
               height: 30px;
               width: 70%;
               border-width: 0.1px;
               background: black;
               color: white;

               font-size: 11px;
               letter-spacing: 1px;

               margin: 10px auto;
               display: block;
               }

}

@media screen and (min-width:600px) and (max-width:960px){
               .send-order-info-mail{
                              width: 50%;
                              height: 500px;
                              margin: 50px auto;
                              }
               
                              .send-order-info-mail input{
                              height: 30px;
                              width: 90%;
                              margin: 10px auto;
                              display: block;
                              border-width: 0.1px;
                              }
               
                              .send-order-info-mail button{
                              height: 30px;
                              width: 70%;
                              border-width: 0.1px;
                              background: black;
                              color: white;
               
                              font-size: 11px;
                              letter-spacing: 1px;
                              display: block;
                              margin: 10px auto;
               
                              }
}

@media screen and (min-width:960px) and (max-width:1280px){
               .send-order-info-mail{
                              width: 50%;
                              height: 500px;
                              margin: 50px auto;
                              }
               
                              .send-order-info-mail input{
                              height: 30px;
                              width: 90%;
                              margin: 10px auto;
                              display: block;
                              border-width: 0.1px;
                              }
               
                              .send-order-info-mail button{
                              height: 40px;
                              width: 70%;
                              border-width: 0.1px;
                              background: black;
                              color: white;
               
                              font-size: 13px;
                              letter-spacing: 1px;
                              display: block;
                              margin: 30px auto;
               
                              }
}

@media screen and (min-width:1280px){
               .send-order-info-mail{
                              width: 30%;
                              height: 500px;
                              margin: 50px auto;
                              }
               
                              .send-order-info-mail input{
                              height: 30px;
                              width: 90%;
                              margin: 10px auto;
                              display: block;
                              border-width: 0.1px;
                              }
               
                              .send-order-info-mail button{
                              height: 40px;
                              width: 70%;
                              border-width: 0.1px;
                              background: black;
                              color: white;
               
                              font-size: 13px;
                              letter-spacing: 1px;
                              display: block;
                              margin: 30px auto;
               
                              }
}