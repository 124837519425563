@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){
    .xl-notebook{
        display: none;
    }
    .l-notebook{
        display: none;
    }
    .m-notebook{
        display: none;
    }
    .s-notebook{
        display: none;
    }
    .xs-notebook{
        width: 100%;

    }

}


@media screen and (min-width:600px) and (max-width:768px){
    .xl-notebook{
        display: none;
    }
    .l-notebook{
        display: none;
    }
    .m-notebook{
        display: none;
    }
    .s-notebook{
        width: 100%;
    }
    .xs-notebook{
        display: none;
    }

}

@media screen and (min-width:768px) and (max-width:992px){
    .xl-notebook{
        display: none;
    }
    .l-notebook{
        display: none;
    }
    .m-notebook{
        width: 100%;
    }
    .s-notebook{
        display: none;
    }
    .xs-notebook{
        display: none;
    }

}

@media screen and (min-width:992px) and (max-width:1200px){
    .xl-notebook{
        display: none;
    }
    .l-notebook{
        width: 100%;
    }
    .m-notebook{
        display: none;
    }
    .s-notebook{
        display: none;
    }
    .xs-notebook{
        display: none;
    }
}


@media screen and (min-width:1200px) and (max-width:1600px){
    .xl-notebook{
        display: none;
    }
    .l-notebook{
        width: 100%;
    }
    .m-notebook{
        display: none;
    }
    .s-notebook{
        display: none;
    }
    .xs-notebook{
        display: none;
    }


}


@media screen and (min-width:1600px) and (max-width:1920px){
    .xl-notebook{
        width: 100%;
    }
    .l-notebook{
        display: none;
    }
    .m-notebook{
        display: none;
    }
    .s-notebook{
        display: none;
    }
    .xs-notebook{
        display: none;
    }

}



.homepage-container{
    margin-top: 50px;
    margin-bottom: -150px;
    position: relative;
    width: 100%;
    /* display: flex;
    justify-content: center; */
}

.my-clickable{
    background-color:rgba(52, 52, 52, 0);

    position:absolute;
    transform: rotate(5deg);
    top:18.3%;
    left:11%;
    height: 7%;
    width: 28%;
    z-index: 999999;
    cursor: pointer;

}


.my-clickable-second{
    background-color:rgba(52, 52, 52, 0);

    position:absolute;
    transform: rotate(5deg);
    top:65.5%;
    right:11%;
    height: 7%;
    width: 28%;
    z-index: 999999;
    cursor: pointer;

}














