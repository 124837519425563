@font-face {
    font-family: "regular";
    src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){
    .checkout-div{
        font-family: "regular";
        letter-spacing: .97px;
        line-height: 15px;
        font-size: 10px;
        margin: 30px auto !important;
    }

    .checkout-shopping-bag-container{
        margin-top: 40px;
    }

    .checkout-shopping-bag-item-details{
        width: 50%;
        height: 30px;
        margin-left: 15px;
        white-space: nowrap;
        
    }
    .checkout-basket-image{
        width: 100%;
    }

    .checkout-black-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
        border: none;
    }
    
    .checkout-grey-button{
        width: 100%;
        height: 40px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.7px;
        font-size: 10px;
    }
    
    
    
    .checkout-small-space{
        height: 40px;
    }
    
    .checkout-x-small-space{
        height: 12px;
    }
    
    
    
    .checkout-large-input{
        padding-left: 30px;
        width: 100%;
        height: 42px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;
        letter-spacing: 1.7px;
        font-size: 10px;
    }

    .checkout-small-input{
        padding-left: 30px;
        width: 100%;
        height: 42px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;
        letter-spacing: 1.7px;
        font-size: 10px;

    }

    .checkout-small-title{
        margin-top: 20px;
        margin-bottom: 10px;

    }

    .checkout-carpi{
        width: 100%;
    }

}
@media screen and (min-width:600px) and (max-width:960px){
    .checkout-div{
        font-family: "regular";
        letter-spacing: 1px;
        line-height: 15px;
        font-size: 10px;
        margin:20px auto;
    }

    .checkout-shopping-bag-container{
        margin-top: 45px;
    }

     
    .checkout-small-space{
        height: 15px;
    }
    
    .checkout-x-small-space{
        height: 12px;
    }

    .checkout-large-input{
        padding-left: 30px;
        width: 100%;
        height: 30px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;
        font-size: 9px;
        letter-spacing: .9px;
    }

    .checkout-small-input{
        padding-left: 30px;
        width: 100%;
        font-size: 9px;
        height: 30px;
        border-width: .9px;
        letter-spacing: .9px;
        font-family: "regular";
        box-sizing: border-box;

    }

    .checkout-shopping-bag-item-details{
        width: 90%;
        height: 30px;
        margin-left: 13%;
        font-size: 9px;
    }
    .checkout-basket-image{
        width: 100%;
    }

    .checkout-black-button{
        width: 100%;
        height: 35px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 8px;
        border: none;
    }
    
    .checkout-grey-button{
        width: 100%;
        height: 35px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.3px;
        font-size: 8px;
    }


    .checkout-small-title{
        margin-top: 20px;
        margin-bottom: 10px;

    }

    .checkout-carpi{
        width: 100%;
        cursor: pointer;
    }

    .checkout-shopping-bag-sum{
        margin-top: -20px;
    }
   

}
@media screen and (min-width:960px) and (max-width:1280px){
    .checkout-div{
        font-family: "regular";
        letter-spacing: 1px;
        line-height: 18px;
        font-size: 11.3px;
        margin:auto;
    }

    .checkout-shopping-bag-container{
        margin-top: 45px;
    }
     
    .checkout-small-space{
        height: 15px;
    }
    
    .checkout-x-small-space{
        height: 12px;
    }

    .checkout-large-input{
        padding-left: 30px;
        width: 100%;
        height: 30px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;
        font-size: 10px;
        letter-spacing: .9px;
    }

    .checkout-small-input{
        padding-left: 30px;
        width: 100%;
        font-size: 10px;
        height: 30px;
        border-width: .9px;
        letter-spacing: .9px;
        font-family: "regular";
        box-sizing: border-box;

    }

    .checkout-shopping-bag-item-details{
        width: 90%;
        height: 30px;
        margin-left: 20px;
        font-size: 10px;
    }
    .checkout-basket-image{
        width: 100%;
    }

    .checkout-black-button{
        width: 100%;
        height: 35px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 8px;
        border: none;
    }
    
    .checkout-grey-button{
        width: 100%;
        height: 35px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: 1.3px;
        font-size: 8px;
    }
    
    

    .checkout-price {

        font-size: 10px;
    }

    .checkout-small-title{
        margin-top: 20px;
        margin-bottom: 10px;

    }
   .checkout-carpi{
        width: 100%;
        cursor: pointer;
    }

    .checkout-shopping-bag-sum{
        margin-top: -30px;
    }
    
    
    
    
}

@media screen and (min-width:1280px) {
    .checkout-div{
        font-family: "regular";
        letter-spacing: .97px;
        line-height: 21px;
        font-size: 14px;
        margin: auto;
    }
    .checkout-shopping-bag-item-details{
        width: 50%;
        height: 30px;
        margin-left: 13%;
    }
    .checkout-basket-image{
        width: 100%;
    }

    .checkout-black-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
        border: none;
    }
    
    .checkout-grey-button{
        width: 100%;
        height: 40px;
        border: none;
        background: #E6E4DC;
        color: black;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
    }
    
    
    .checkout-small-space{
        height: 40px;
    }
    
    .checkout-x-small-space{
        height: 12px;
    }
    
    .checkout-large-input{
        padding-left: 30px;
        width: 100%;
        height: 42px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;
    }

    .checkout-small-input{
        padding-left: 30px;
        width: 100%;
        height: 42px;
        border-width: .9px;
        font-family: "regular";
        box-sizing: border-box;

    }

    .checkout-small-title{
        margin-top: 20px;
        margin-bottom: 10px;

    }

    .checkout-carpi{
        width: 100%;
        cursor: pointer;
    }

    .checkout-shopping-bag-container{
        margin-top: 45px;
    }

    .checkout-shopping-bag-sum{
        margin-top: -20px;
    }
}


