@font-face {
    font-family: "regular";
    src: url("../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){
    .iframe-payment {
        display: block;
        margin: auto;
        border: none;
        width: 80%;
        height: 690px;
        margin-top: 30px;
        font-family: "regular";
       }

}
@media screen and (min-width:600px) and (max-width:960px){
    .iframe-payment {
        display: block;
        margin: auto;
        border: none;
        width: 80%;
        height: 690px;
        margin-top: 30px;
        font-family: "regular";
       }

}
@media screen and (min-width:960px) and (max-width:1280px){
    .iframe-payment {
        display: block;
        margin: auto;
        border: none;
        width: 80%;
        height: 690px;
        margin-top: 30px;
        font-family: "regular";
       }


}
@media screen and (min-width:1280px) {
    .iframe-payment {
        font-family: "regular";
        display: block;
        margin: auto;
        border: none;
        width: 80%;
        height: 690px;
        margin-top: 30px;
       }

    
}