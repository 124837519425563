

.wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width:600px){
    .headerHome {
        width: 120px;
        text-align: center;
        }

}
@media screen and (min-width:600px) and (max-width:960px){
    .headerHome {
        width: 120px;
        text-align: center;
        }

}
@media screen and (min-width:960px) and (max-width:1280px){
    .headerHome {
        width: 160px;
    
    }
}
@media screen and (min-width:1280px) {
    .headerHome {
        width: 170px;
    
    }
}