.edit-single-form {
               width: 90%;
               margin:100px auto;
               text-align: center;
           }
           
           .edit-single-form label {
               text-align: left;
               display: block;
           }
           
           .edit-single-form h2 {
               font-size:20px;
               margin-bottom: 30px;
           }
           
           .edit-single-form input, .edit-single-form textarea {
               width: 100%;
               padding: 6px 10px;
               margin:10px 0;
               border:1px solid black;
               box-sizing: border-box;
               display: block;
           }
           
           .edit-single-form button {
               background:black;
               color: white;
               border: 0;
               padding: 8px;
               cursor: pointer;
           }


           .edit-product-image-small{
               width: 100%;
            }

               
           
           