


@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}

@font-face {
    font-family: "bold";
    src: url("../../components/fonts/Linotype_Brewery_W01_Bold.ttf");
}

@media screen and (max-width:600px){
    .subscribe-newsletter{
        margin-top: 30px;
        margin-bottom: 100px;

    }

    .subscribe-newsletter-text{
        margin: 0 auto;

        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 2px;
    }

    .subscribe-newsletter-input{
        margin: 70px auto;
        width: 70%;

        display: flex;
        align-items: center;
        border-top: none;
        border-left: none;
        border-right: none;
        border-width: 0.001px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 3px;
        line-height: 25px;
    }


    .subscribe-newsletter-black-sign-up-button{
        width: 60%;
        height: 25px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 10px;
        margin-left: 25%;

    }

    .subscribe-newsletter-img{
        width: 100%;
        height: 100%;
        margin-left: -20%;
    }
    
}
@media screen and (min-width:600px) and (max-width:960px){
    .subscribe-newsletter{
        margin-top: 30px;
        margin-bottom: 100px;

    }

    .subscribe-newsletter-text{
        margin: 0 auto;
        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    .subscribe-newsletter-input{
        margin: auto;
        margin-top: 25px;
        width: 50%;

        display: flex;
        align-items: center;
        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 0.001px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 3px;
        line-height: 25px;
    }

    .subscribe-newsletter-input-border{
        margin:  auto;
    }

    .subscribe-newsletter-black-sign-up-button{
        width: 90%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 10px;
        margin-left: 6%;
        border:none;
        margin-top: 50px;

    }

    .subscribe-newsletter-img{
        width: 100%;
        margin-top: 30px;
    }
}
@media screen and (min-width:960px) and (max-width:1280px){
    .subscribe-newsletter{
        margin-top: 30px;
        margin-bottom: 100px;

    }

    .subscribe-newsletter-text{
        margin: 0 auto;
        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    .subscribe-newsletter-input{
        margin: auto;
        margin-top: 20px;
        width: 40%;

        display: flex;
        align-items: center;
        border-top: none;
        border-right: none;
        border-left: none;
        border-width: 0.001px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 3px;
        line-height: 25px;
    }

    .subscribe-newsletter-input-border{
        margin:  auto;
    }

    .subscribe-newsletter-black-sign-up-button{
        width: 90%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 10px;
        margin-left: 6%;
        border:none;
        margin-top: 50px;

    }

    .subscribe-newsletter-img{
        width: 100%;
        margin-top: 30px;
    }
}
@media screen and (min-width:1280px) and (max-width:1400px){
    .subscribe-newsletter{
        margin-top: 30px;
        margin-bottom: 100px;

    }

    .subscribe-newsletter-text{
        margin: 0 auto;

        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    .subscribe-newsletter-input{
        margin: 70px auto;
        width: 40%;

        display: flex;
        align-items: center;
        border-top: none;
        border-left: none;
        border-right: none;
        border-width: 0.001px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 13px;
        letter-spacing: 3px;
        line-height: 25px;
    }


    .subscribe-newsletter-black-sign-up-button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 13px;
        margin-left: 6%;

    }

    .subscribe-newsletter-img{
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width:1400px) and (max-width:1800px){
    
    .subscribe-newsletter{
        margin-top: 30px;
        margin-bottom: 100px;

    }

    .subscribe-newsletter-text{
        margin: 0 auto;

        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    .subscribe-newsletter-input{
        margin: 70px auto;
        width: 40%;

        display: flex;
        align-items: center;
        border-top: none;
        border-left: none;
        border-right: none;
        border-width: 0.001px;
        border-color: lightgrey;
        font-family: "regular";
        font-size: 13px;
        letter-spacing: 3px;
        line-height: 25px;
    }


    .subscribe-newsletter-black-sign-up-button{
        width: 90%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 3px;
        font-size: 13px;
        margin-left: 6%;

    }

    .subscribe-newsletter-img{
        width: 100%;
        height: 100%;
    }
    
}