:root {

    /* Colors: */
    --unnamed-color-00ff9d: #00FF9D;
    --unnamed-color-00ffff: #00FFFF;
    --unnamed-color-fafaf6: #FAFAF6;
    --unnamed-color-fdfdfa: #FDFDFA;
    --unnamed-color-fcfcfc: #FCFCFC;
    --unnamed-color-f7ecdb: #F7ECDB;
    --unnamed-color-19383f: #19383F;
    --unnamed-color-cd215e: #CD215E;
    --unnamed-color-45334b: #45334B;
    --unnamed-color-080000: #080000;
    
    
    /* Font/text values */
    --unnamed-font-family-linotypebreweryw01-bold: LinotypeBreweryW01-Bold;
    --unnamed-font-family-linotypebreweryw01-regular: LinotypeBreweryW01-Light;
    --unnamed-font-style-normal:regular;
    --unnamed-font-weight-normal: regular;
    --unnamed-font-size-15: 14px;
    --unnamed-font-size-40: 60px;
    --unnamed-character-spacing-2-25: 2.25px;
    --unnamed-character-spacing-0-97: 0.97px;
    --unnamed-character-spacing-5-6: 5.6px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-54: 54px;
    --unnamed-text-transform-uppercase: uppercase;
}

@font-face {
    font-family: "medium";
    src: url("../fonts/Linotype_Brewery_Medium.ttf");
}
@font-face {
    font-family: "bold";
    src: url("../fonts/Linotype_Brewery_W01_Bold.ttf");
}
@font-face {
    font-family: "regular";
    src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}

@media screen and (max-width:600px){

    .small-size-navbar-grid-wrapper{
        height: 60px;

    } 
    .small-size-mavbar-grid{
        position: fixed;
        z-index: 99999999999;
        height: 60px;
    }
    
    .logo-small{
        width: 100%;
    }

    .burger-menu{
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 2px;
        z-index: 99999999999999999999999;
        position: fixed;
    }

    .burger-menu-item{
        text-decoration: none;
        color: black;
    }

    .burger-menu-item-wrapper {
        padding-top: 7px;
        height: 25px;
    }

    .search-link{
        color:black;
        text-decoration:none;
        cursor:pointer;
        border: none;
        font-family: "bold";
        font-size: 13.85px;
        letter-spacing: 1.7px;

        display:flex; 
        justify-content:center;

        position: relative;
        bottom:0.36px;
    }
}
@media screen and (min-width:600px) and (max-width:960px){
    .big-size-navbar-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    } 
    
    .big_size_navbar_container{
        height: 60px;
        /* border:#00FF9D solid; */
        z-index: 100000000;
        padding-top: 10px;
        position: fixed;
    }

    .navElements{
        font-family: "bold";
        font-size: 8px;
        letter-spacing: 1px;
        display:flex; 
        justify-content:flex-start;
        /* border: #00FF9D solid; */
    }

    .right{
        text-align: right;
    }

    .navLink{
        text-decoration:none;
        color: var(--unnamed-color-080000);

    }

    .logo-container{
        /* border: red solid; */
        display:flex; 
        justify-content:center;
    }
    
    .logo{
        width: 70%;
    }


    .search-link{
        color:black;
        text-decoration:none;
        cursor:pointer;
        border: none;
        font-family: "bold";
        font-size: 13.85px;
        letter-spacing: 1.7px;
        display:flex; 
        justify-content:center;
        position: relative;
        bottom:0.36px;



    }

}
@media screen and (min-width:960px) and (max-width:1280px){
    .big-size-navbar-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
    } 
    
    .big_size_navbar_container{
        height: 70px;
        padding-top: 10px;
        /* border:#00FF9D solid; */
        z-index: 100000000;
        position: fixed;
    }

    .navElements{
        font-family: "bold";
        font-size: 9.5px;
        letter-spacing: .97px;
        display:flex; 
        justify-content:flex-start;
        /* border: #00FF9D solid; */
    }

    .right{
        text-align: right;
    }

    .navLink{
        text-decoration:none;
        color: var(--unnamed-color-080000);

    }

    .logo-container{
        /* border: red solid; */
        display:flex; 
        justify-content:center;
    }
    
    .logo{
        width: 70%;
    }


    .search-link{
        color:black;
        text-decoration:none;
        cursor:pointer;
        border: none;
        font-family: "bold";
        font-size: 13.85px;
        letter-spacing: 1.7px;
        display:flex; 
        justify-content:center;
        position: relative;
        bottom:0.36px;



    }
    


}
@media screen and (min-width:1280px){

    .big-size-navbar-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;


    } 

    .big_size_navbar_container{
        position: fixed;
        z-index: 9999;
        height: 100px;

        /* top: 14%;
        left: 50%;
        transform: translate(-50%, -50%); */
    }

    .navElements{
        font-family: "bold";
        font-size: 12px;
        letter-spacing: 1.9px;
        /* border: #00FF9D solid; */
    }

    .navLink{
        text-decoration:none;
        color: var(--unnamed-color-080000);

    }
    .right{
        text-align: right;
    }

    .logo-container{
        /* border: red solid; */
        display:flex; 
        justify-content:center;
    }
    
    .logo{
        width: 70%;
    }


    .search-link{
        color:black;
        text-decoration:none;
        cursor:pointer;
        border: none;
        font-family: "bold";
        font-size: 13.85px;
        letter-spacing: 1.7px;
        display:flex; 
        justify-content:center;
        position: relative;
        bottom:0.36px;



    }
    
}


       

   




   