@font-face {
    font-family: "regular";
    src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}

.slide-up {
    animation: slide-up 0.5s ease forwards;
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

@media screen and (max-width:600px){
    .product-details-container{
        margin: auto;
    }
    .product-details{
        font-family: "regular";
        line-height: 21px;
    }

    .product-type{
        font-size: 12px;
        letter-spacing: 2.5px;
    }
    
    .product-name{
        font-size: 10px;
        letter-spacing: 2.5px;
    }

    .product-explanation{
        font-size: 10px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .one-size-fits-all{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .product-price-currency{
        font-size: 10px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .product-material-title{
        font-size: 10px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-care-instructions-title{
        font-size: 10px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-material{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .product-care-instructions{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .spaceLarge{
        height: 30px;
    }
    
    .spaceSmall{
        height: 10px;
    }
    .spaceXsmall{
        height: 7px;
    }

    .shop-single-add-to-cart-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.8px;
        border: none;
    }

    .product-main-image{
        cursor:pointer;

        width: 100%;
    }
    
    .product-basket-image{
        width: 100%;
    }
    
    .shop-single-navbar{
        width: 100%;
        padding: 1px 0;
        z-index: 10;
        top:100px;
        transition: all 1s;
        right: -100%;
        font-family: "regular";
        line-height: 17px;
    }
    
    .shop-single-navbar.active{
        width: 100%;
        right: 0;
    }
    .side-bar-product-type{
        font-size: 10px;
        letter-spacing: 1.5px;
    }
    
    .side-bar-product-color-name{
        font-size: 10px;
        letter-spacing: 1.5px;
    }
    .side-bar-product-name{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .side-bar-product-item-currency{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .delete-button{
        border:none;
        padding: 0;
        font-family: "regular";
        color: grey;
    }

    .shop-single-checkout-button{
        width: 100%;
        margin-top: 10px;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.8px;
        border: none;
        margin-bottom: 100px;

    }
    
    /* .container-dots {
        transform: translateX(-50%);
        background: red;
      } */
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #AAAAAA;
        margin: 0 5px;
        display: inline-block;
      }
      .dot.active {
        border: 2px solid black;
      }
}

@media screen and (min-width:600px) and (max-width:960px){

    .product-details{
        font-family: "regular";
        line-height: 17px;
    }

    .product-type{
        font-size: 12px;
        letter-spacing: 2.5px;
    }
    
    .product-name{
        font-size: 10px;
        letter-spacing: 2.5px;
    }

    .product-explanation{
        font-size: 10px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .one-size-fits-all{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .product-price-currency{
        font-size: 10px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .product-material-title{
        font-size: 10px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-care-instructions-title{
        font-size: 10px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-material{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .product-care-instructions{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .spaceLarge{
        height: 30px;
    }
    
    .spaceSmall{
        height: 10px;
    }
    .spaceXsmall{
        height: 7px;
    }

    .shop-single-add-to-cart-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 1.8px;
        border: none;
    }

    .product-main-image{
        cursor:pointer;

        width: 100%;
    }
    
    .product-basket-image{
        width: 100%;
    }
    
    .shop-single-navbar{
        width: 100%;
        padding: 1px 0;
        z-index: 10;
        top:100px;
        transition: all 1s;
        right: -100%;
        font-family: "regular";
        line-height: 17px;
    }
    
    .shop-single-navbar.active{
        width: 100%;
        right: 0;
    }
    .side-bar-product-type{
        font-size: 10px;
        letter-spacing: 1.5px;
    }
    
    .side-bar-product-color-name{
        font-size: 10px;
        letter-spacing: 1.5px;
    }
    .side-bar-product-name{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .side-bar-product-item-currency{
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    .delete-button{
        border:none;
        padding: 0;
        font-family: "regular";
        color: grey;
    }

    .shop-single-checkout-button{
        width: 100%;
        margin-top: 10px;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.8px;
        border: none;
        margin-bottom: 100px;

    }
    
    /* .container-dots {
        transform: translateX(-50%);
        background: red;
      } */
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #AAAAAA;
        margin: 0 5px;
        display: inline-block;
      }
      .dot.active {
        border: 2px solid black;
      }
}

@media screen and (min-width:960px) and (max-width:1280px){

    .product-details{
        font-family: "regular";
        line-height: 21px;
    }

    .product-type{
        font-size: 16px;
        letter-spacing: 2.5px;
    }
    
    .product-name{
        font-size: 11px;
        letter-spacing: 2.5px;
    }

    .product-explanation{
        font-size: 11px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .one-size-fits-all{
        font-size: 11px;
        letter-spacing: 1.5px;
    }

    .product-price-currency{
        font-size: 11px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .product-material-title{
        font-size: 11px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-care-instructions-title{
        font-size: 11px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-material{
        font-size: 11px;
        letter-spacing: 1.5px;
    }

    .product-care-instructions{
        font-size: 11px;
        letter-spacing: 1.5px;
    }

    .spaceLarge{
        height: 30px;
    }
    
    .spaceSmall{
        height: 10px;
    }
    .spaceXsmall{
        height: 7px;
    }

    .shop-single-add-to-cart-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.8px;
        border: none;
    }

    .product-main-image{
        cursor:pointer;

        width: 100%;
    }
    
    .product-basket-image{
        width: 100%;
    }
    
    .shop-single-navbar{
        width: 100%;
        padding: 1px 0;
        z-index: 10;
        top:100px;
        transition: all 1s;
        right: -100%;
        font-family: "regular";
        line-height: 17px;
    }
    
    .shop-single-navbar.active{
        width: 100%;
        right: 0;
    }
    .side-bar-product-type{
        font-size: 12px;
        letter-spacing: 1.5px;
    }
    
    .side-bar-product-color-name{
        font-size: 12px;
        letter-spacing: 1.5px;
    }
    .side-bar-product-name{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .side-bar-product-item-currency{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .delete-button{
        border:none;
        padding: 0;
        font-family: "regular";
        color: grey;
    }

    .shop-single-checkout-button{
        width: 100%;
        margin-top: 10px;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.8px;
        border: none;
        margin-bottom: 100px;

    }
    
    /* .container-dots {
        transform: translateX(-50%);
        background: red;
      } */
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #AAAAAA;
        margin: 0 5px;
        display: inline-block;
      }
      .dot.active {
        border: 2px solid black;
      }
    
}

@media screen and (min-width:1280px){

    .product-details{
        font-family: "regular";
        line-height: 21px;
    }

    .product-type{
        font-size: 18px;
        letter-spacing: 2.5px;
    }
    
    .product-name{
        font-size: 12px;
        letter-spacing: 2.5px;
    }

    .product-explanation{
        font-size: 12px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }


    .one-size-fits-all{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .product-price-currency{
        font-size: 12px;
        letter-spacing: .97px;
        word-wrap: break-word;
    }

    .product-material-title{
        font-size: 12px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-care-instructions-title{
        font-size: 12px;
        letter-spacing: 1.5px;
        font-family: "bold";
    }

    .product-material{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .product-care-instructions{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .spaceLarge{
        height: 30px;
    }
    
    .spaceSmall{
        height: 10px;
    }
    .spaceXsmall{
        height: 7px;
    }

    .shop-single-add-to-cart-button{
        width: 100%;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 1.8px;
        border: none;
    }

    .product-main-image{
        cursor:pointer;
        width: 100%;
    }
    
    .product-basket-image{
        width: 100%;
    }
    
    .shop-single-navbar{
        width: 100%;
        padding: 1px 0;
        z-index: 10;
        top:100px;
        transition: all 1s;
        right: -100%;
        font-family: "regular";
        line-height: 17px;
    }
    
    .shop-single-navbar.active{
        width: 100%;
        right: 0;
    }
    .side-bar-product-type{
        font-size: 12px;
        letter-spacing: 1.5px;
    }
    
    .side-bar-product-color-name{
        font-size: 12px;
        letter-spacing: 1.5px;
    }
    .side-bar-product-name{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .side-bar-product-item-currency{
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .delete-button{
        border:none;
        padding: 0;
        font-family: "regular";
        color: grey;
    }

    .shop-single-checkout-button{
        width: 100%;
        margin-top: 10px;
        height: 30px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.8px;
        border: none;
        margin-bottom: 100px;

    }
    
    /* .container-dots {
        transform: translateX(-50%);
        background: red;
      } */
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #AAAAAA;
        margin: 0 5px;
        display: inline-block;
      }
      .dot.active {
        border: 2px solid black;
      }
    
}


  