@font-face {
               font-family: "regular";
               src: url("../components/fonts/Linotype_Brewery_W01_Regular.ttf");
           }


@media screen and (max-width:600px){
               .forgot-password-form{
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              margin-top: 50px;
                              padding-left: 20px;
                              padding-right: 20px;
                              font-family: "regular";
                              padding-bottom: 100px;
                          }
                          
                          .forgot-password-form-title{
                               font-size: 11px;
                               text-align: center;
                               margin-bottom: 20px;
                               line-height: 20px;
                               letter-spacing: 2px;
                          }
                          
                          
                          .forgot-password-form-input{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                margin: auto;
                                border: none; 
                                border-bottom:1px solid #707070;
                                color: #696565;
                                font-family: "regular";
                                line-height: 25px;
                                letter-spacing: 2px;
                                font-size: 10px;
                                margin-bottom: 10px;
                                border-bottom:1px solid lightgrey;
                          }
                                     
                          .forgot-password-form-input:focus{
                                         /* border: 1px solid #707070; */
                                     
                             outline: none;
                             border-color: #707070;
                          }
                          
                          
                          .forgot-password-form-small-space{
                                         height: 20px;
                          }
                          
                          .forgot-password-form-button{
                          
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                         
                              width: 70%;
                              margin-top: 50px;
                              height: 30px;
                              background: black;
                              color: white;
                              font-family: "regular";
                              font-size: 8px;
                              letter-spacing: 4px;
                              
                          }
}
@media screen and (min-width:600px) and (max-width:960px){
               .forgot-password-form{
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              margin-top: 50px;
                              font-family: "regular";
                              letter-spacing: 2px;
                              padding-bottom: 100px;
                          }
                          
                          .forgot-password-form-title{
                               font-size: 13px;
                               margin-bottom: 50px;
                          }
                          
                          
                          .forgot-password-form-input{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                margin: auto;
                                border: none; 
                                border-bottom:1px solid #707070;
                                color: #696565;
                                font-family: "regular";
                                line-height: 25px;
                                letter-spacing: 2px;
                                font-size: 10px;
                                margin-bottom: 10px;
                                border-bottom:1px solid lightgrey;
                          }
                                     
                          .forgot-password-form-input:focus{
                                         /* border: 1px solid #707070; */
                                     
                             outline: none;
                             border-color: #707070;
                          }
                          
                          
                          .forgot-password-form-small-space{
                                         height: 20px;
                          }
                          
                          .forgot-password-form-button{
                          
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                         
                              width: 70%;
                              margin-top: 40px;
                              height: 30px;
                              background: black;
                              color: white;
                              font-size: 9px;
                              letter-spacing: 2px;

                              font-family: "regular";
                          }
}
@media screen and (min-width:960px) and (max-width:1280px){
               .forgot-password-form{
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              margin-top: 50px;
                              font-family: "regular";
                              letter-spacing: 2.3px;
                              padding-bottom: 100px;
                          }
                          
                          .forgot-password-form-title{
                               font-size: 13px;
                               margin-bottom: 40px;
                          }
                          
                          
                          .forgot-password-form-input{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                margin: auto;
                                border: none; 
                                border-bottom:1px solid #707070;
                                color: #696565;
                                font-family: "regular";
                                line-height: 25px;
                                letter-spacing: 2px;
                                font-size: 11px;

                                margin-bottom: 10px;

        border-bottom:1px solid lightgrey;
                          }
                                     
                          .forgot-password-form-input:focus{
                                         /* border: 1px solid #707070; */
                                     
                             outline: none;
                             border-color: #707070;
                          }
                          
                          
                          .forgot-password-form-small-space{
                                         height: 20px;
                          }
                          
                          .forgot-password-form-button{
                          
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                         
                              width: 80%;
                              margin-top: 50px;
                              height: 30px;
                              background: black;
                              letter-spacing: 2px;
                              color: white;
                              font-size: 9px;
                              font-family: "regular";
                          }
}
@media screen and (min-width:1280px) {

               .forgot-password-form{
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              margin-top: 50px;
                              font-family: "regular";
                              letter-spacing: 2px;
                              padding-bottom: 100px;
                          }
                          
                          .forgot-password-form-title{
                               font-size: 17px;
                               margin-bottom: 50px;
                          }
                          
                          
                          .forgot-password-form-input{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                margin: auto;
                                border: none; 
                                border-bottom:1px solid #707070;
                                color: #696565;
                                font-family: "regular";
                                line-height: 25px;
                                letter-spacing: 2px;
                                font-size: 12px;
                                margin-bottom: 10px;
                                border-bottom:1px solid lightgrey;
                          }
                                     
                          .forgot-password-form-input:focus{
                                         /* border: 1px solid #707070; */
                                     
                             outline: none;
                             border-color: #707070;
                          }
                          
                          
                          .forgot-password-form-small-space{
                                         height: 20px;
                          }
                          
                          .forgot-password-form-button{
                          
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                font-family: "bold";
                                letter-spacing: 2px;
                                         
                              width: 50%;
                              margin-top: 50px;
                              height: 30px;
                              background: black;
                              color: white;
                              font-size: 12px;
                          }

}
