@font-face {
    font-family: "regular";
    src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width:600px) {
    .product-image-small {
        width: 100%;
    }

    .image-container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .image-container.not-clickable {
        pointer-events: none;
        opacity: 0.6;
    }

    .sold-out-container {
        height: 100%;
        width: 100%;
        background-color: rgba(52, 52, 52, 0.3);
        position: absolute;
    }

    .sold-out {
        height: 25px;
        width: 50%;
        background: black;
        color: white;
        position: absolute;
        top: 0px;
        right: 0px;
        padding-top: 10px;
        font-size: 12px;
        text-align: center;
        font-family: "bold";
        letter-spacing: .9px;
    }

    .products-grid {
        font-family: "regular";
        letter-spacing: 1.6px;
        font-size: 12px;
        padding-top: 30px;



        animation-name: slide-up;
        animation-duration: 1s;
        /* 1 second duration */
        animation-timing-function: ease;
        /* Ease timing function */
        /* animation-delay: 0s; */

        animation-fill-mode: forwards;
    }

    .products-item-info-container {
        padding-top: 1%;
        padding-bottom: 10%;
        line-height: 22px;
    }

}

@media screen and (min-width:600px) and (max-width:960px) {

    .product-image-small {
        height: 100%;
        width: 90%;

    }

    .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .image-container.not-clickable {
        pointer-events: none;
        opacity: 0.6;
    }

    .sold-out-container {
        height: 100%;
        width: 90%;
        background-color: rgba(52, 52, 52, 0.3);
        position: absolute;
    }


    .sold-out {
        height: 20px;
        width: 50%;
        background: black;
        color: white;
        position: absolute;
        top: 0px;
        right: 0px;
        padding-top: 7px;
        font-size: 9px;
        letter-spacing: 0.9;
        text-align: center;
        font-family: "bold";
    }

    .products-grid {
        font-family: "regular";
        letter-spacing: 1.4px;
        font-size: 10px;
        padding-top: 40px;

        animation-name: slide-up;
        animation-duration: 1s;
        /* 1 second duration */
        animation-timing-function: ease;
        /* Ease timing function */
        /* animation-delay: 0s; */

        animation-fill-mode: forwards;
    }

    .products-item-info-container {
        padding-top: 5%;
        padding: 5%;
        line-height: 22px;
    }

}

@media screen and (min-width:960px) and (max-width:1280px) {

    .product-image-small {
        height: 100%;
        width: 90%;
    }

    .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;


    }

    .sold-out-container {
        height: 100%;
        width: 90%;
        background-color: rgba(52, 52, 52, 0.3);
        position: absolute;
    }


    .sold-out {
        height: 25px;
        width: 50%;
        background: black;
        color: white;
        position: absolute;
        top: 0px;
        right: 0px;
        padding-top: 10px;
        font-size: 11px;
        text-align: center;
        font-family: "bold";
    }

    .products-grid {
        font-family: "regular";
        letter-spacing: 1.4px;
        font-size: 11px;
        padding-top: 40px;


        animation-name: slide-up;
        animation-duration: 1s;
        /* 1 second duration */
        animation-timing-function: ease;
        /* Ease timing function */
    }

    .products-item-info-container {
        padding-top: 5%;
        padding: 5%;
        line-height: 22px;
    }
}


@media screen and (min-width:1280px) {

    .product-image-small {
        height: 100%;
        width: 90%;

    }

    .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .sold-out-container {
        height: 100%;
        width: 90%;
        background-color: rgba(52, 52, 52, 0.3);
        position: absolute;
    }

    .sold-out {
        height: 25px;
        width: 50%;
        background: black;
        color: white;
        position: absolute;
        top: 0px;
        right: 0px;
        padding-top: 10px;
        font-size: 13px;
        text-align: center;
        font-family: "bold";
    }

    .products-grid {
        font-family: "regular";
        letter-spacing: 1.6px;
        font-size: 13px;
        padding-top: 50px;

        animation-name: slide-up;
        animation-duration: 1s;
        /* 1 second duration */
        animation-timing-function: ease;
        /* Ease timing function */
        /* animation-delay: 0s; */

        animation-fill-mode: forwards;
    }

    .products-item-info-container {
        padding-top: 5%;
        padding: 5%;
        line-height: 22px;
    }
}