

@media screen and (max-width:600px){

    .contact-us {
        font-family: "regular";
    }

    .contact-us-small-space{
        height: 25px;
    }

    .contact-us-title{
        margin-top: 15px;
        width: 100%;
        text-align: center;
        font-family: "regular";
        font-size: 11px;
        line-height: 10px;
        letter-spacing: 3px;
    }

    .contact-us-info {
        margin-top: -50px;
        letter-spacing: 2px;
        line-height: 20px;
        font-size: 10px;
    }

    .contact-us-image{
        display: none;
    }

    .contact-us-name-input{
        width: 100%;
        height: 30px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-right: 25.5px;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .contact-us-email-input{
        width: 100%;
        height: 30px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-top: 20px;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .contact-us-message-input{
        width: 100%;
        height: 100px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        border-width: 1.1px;
        margin-top: 20px;
        padding-top: -40px;
        padding-left: 10px;
        box-sizing: border-box;
        padding-top: -10px;
    }

    .contact-us-black-sign-up-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 13px;
        margin-top: 20px;
        border: none;
    }

    .shipping-policy-info{
        margin-top: 20px;

        letter-spacing: 2px;
        line-height: 20px;
        font-size: 8px;
    }
     .shipping-policy-info div{
        margin-top: 50px;

    }

}
@media screen and (min-width:600px) and (max-width:960px){

    .contact-us {
        font-family: "regular";
    }

    .contact-us-title{
        width: 100%;
        text-align: center;
        font-family: "regular";
        font-size: 11px;
        line-height: 5px;
        letter-spacing: 3px;
        margin-top: 20px;
    }

    .contact-us-info {
        letter-spacing: 2px;
        line-height: 15px;
        font-size: 8px;
        padding-top: 10px;

    }

    .contact-us-image{
        width: 100%;
        margin-top: 40px;
    }

    .contact-us-small-space{
        height: 20px;
    }

    .contact-us-name-input{
        width: 100%;
        height: 20px;
        font-family: "regular";
        font-size: 8px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-right: 25.5px;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .contact-us-email-input{

        width: 100%;
        height: 20px;
        font-family: "regular";
        font-size: 8px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-top: 10px;
        box-sizing: border-box;
        padding-left: 10px;

    }

    .contact-us-message-input{

        width: 100%;
        height: 40px;
        font-family: "regular";
        font-size: 8px;
        letter-spacing: 3px;
        padding: 5px;
        border-width: 1.1px;
            box-sizing: border-box;
            margin-top: 10px;
    }


    .contact-us-black-sign-up-button{
        width: 100%;
        height: 20px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 8px;
        margin-top: 10px;
    }

    .shipping-policy-info{
        margin-top: 20px;

        letter-spacing: 2px;
        line-height: 20px;
        font-size: 8px;
    }
     .shipping-policy-info div{
        margin-top: 50px;

    }

}
@media screen and (min-width:960px) and (max-width:1280px){

    .contact-us {
        font-family: "regular";
        
    }

    .contact-us-title{

        width: 100%;
        text-align: center;
        font-family: "regular";
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 3px;
        margin-top: 10px;
    }

    .contact-us-info {
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 12px;
    }

    .shipping-policy-info{
        margin-top: 20px;

        letter-spacing: 2px;
        line-height: 20px;
        font-size: 11px;
    }
     .shipping-policy-info div{
        margin-top: 20px;

    }

    .contact-us-image{
        width: 100%;
        }
    
        .contact-us-small-space{
            height: 15px;
        }
    
        .contact-us-name-input{
            width: 100%;
    
            height: 30px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            text-align: left;
            border-width: 1.1px;
            padding-left: 10px;
            box-sizing: border-box;

        }
    
        .contact-us-email-input{
            width: 100%;
    
            height: 30px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            text-align: left;
            border-width: 1.1px;
            box-sizing: border-box;
            padding-left: 10px;
    
        }
    
        .contact-us-message-input{
            width: 100%;
    
            height: 120px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            padding: 5px;
            border-width: 1.1px;
            padding-left: 10px;
            box-sizing: border-box;
            margin-top: 20px;
        }


    .contact-us-black-sign-up-button{
        width: 20%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 13px;
        margin-top: 10px;
    }
}
@media screen and (min-width:1280px) {
    .contact-us {
        font-family: "regular";
    }

    .contact-us-title{

        width: 100%;
        text-align: center;
        font-family: "regular";
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 3px;
        margin-top: 20px;
    }

    .contact-us-info {
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 12px;

    }

    .contact-us-image{
        width: 100%;
        }
    
        .contact-us-small-space{
            height: 25px;
        }
    
        .contact-us-name-input{
            width: 100%;
    
            height: 30px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            text-align: left;
            border-width: 1.1px;
            padding-left: 10px;
            box-sizing: border-box;

        }
    
        .contact-us-email-input{
            width: 100%;
    
            height: 30px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            text-align: left;
            border-width: 1.1px;
            box-sizing: border-box;
            padding-left: 10px;
    
        }
    
        .contact-us-message-input{

            width: 100%;
    
            height: 120px;
            font-family: "regular";
            font-size: 12px;
            letter-spacing: 3px;
            border-width: 1.1px;
            vertical-align: top;
            text-align: left;
            line-height: 60px;
            resize: none;
            box-sizing: border-box;
            padding-left: 10px;
        }

        .contact-us-message-input::placeholder{
            text-align: left;
            vertical-align: top;
            padding-top: -30px;
        }

    
    .contact-us-black-sign-up-button{
        width: 20%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 13px;
        margin-top: 30px;
    }

    .shipping-policy-info{
        margin-top: 20px;

        letter-spacing: 2px;
        line-height: 20px;
        font-size: 11px;
    }
     .shipping-policy-info div{
        margin-top: 20px;

    }
}
/* @media screen and (min-width:1400px) {
    .contact-us {
        font-family: "regular";
        
    }
    .contact-us-info {
        margin-top: -40px;
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 14px;
    }

    .contact-us-title{
        margin: 0 auto;

        width: 30%;
        text-align: center;
        font-family: "regular";
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    
    .contact-us-image{
    width: 100%;
    height: 100%;
    }

    .contact-us-small-space{
        height: 40px;
    }

    .contact-us-name-input{
        width: 47%;
        height: 50px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-right: 10px;
        padding-left: 10px;
    }

    .contact-us-email-input{
        width: 47%;
        height: 50px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        text-align: left;
        border-width: 1.1px;
        margin-top: 20px;
        padding-left: 10px;
    }

    .contact-us-message-input{

        width: 98%;
        height: 115px;
        font-family: "regular";
        font-size: 12px;
        letter-spacing: 3px;
        padding: 5px;
        border-width: 1.1px;
        margin-top: 20px;
        padding-top: -40px;
    }


    .contact-us-black-sign-up-button{
        width: 30%;
        border: none;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 2px;
        font-size: 13px;
        margin-top: 50px;
    }
} */

@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}