.create {
    width: 80%;
    margin:50px auto;
    text-align: center;
    font-size: 12px;
}

.create label {
    text-align: left;
    display: block;
}

.create h2 {
    font-size:20px;
    margin-bottom: 30px;
}

.create input, .create textarea {
    width: 100%;
    padding: 6px 10px;
    margin:10px 0;
    border:1px solid black;
    box-sizing: border-box;
    display: block;
    font-size: 11px;
}

.create button {
    background:black;
    color: white;
    border: 0;
    padding: 8px;
    cursor: pointer;
}

.remove-image-edit-page{
    position: relative;
    bottom: 300px;
    left:0px;
}
