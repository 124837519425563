.admin-panel-container {
  margin: 100px auto;
  width: 300px;
}

.admin-panel-container button{
  display: block;
  width: 200px;
  height: 50px;
  color: white;
  background-color: black;
  margin-top: 50px;

}