:root {

  /* Colors: */
  --unnamed-color-00ffff: #00FFFF;
  --unnamed-color-fafaf6: #FAFAF6;
  --unnamed-color-fdfdfa: #FDFDFA;
  --unnamed-color-fcfcfc: #FCFCFC;
  --unnamed-color-f7ecdb: #F7ECDB;
  --unnamed-color-19383f: #19383F;
  --unnamed-color-cd215e: #CD215E;
  --unnamed-color-45334b: #45334B;
  --unnamed-color-080000: #080000;
  
  
  /* Font/text values */
  --unnamed-font-family-linotypebreweryw01-bold: LinotypeBreweryW01-Bold;
  --unnamed-font-family-linotypebreweryw01-regular: LinotypeBreweryW01-Light;
  --unnamed-font-style-normal:regular;
  --unnamed-font-weight-normal: regular;
  --unnamed-font-size-15: 14px;
  --unnamed-font-size-40: 60px;
  --unnamed-character-spacing-2-25: 2.25px;
  --unnamed-character-spacing-0-97: 0.97px;
  --unnamed-character-spacing-5-6: 5.6px;
  --unnamed-line-spacing-14: 14px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-54: 54px;
  --unnamed-text-transform-uppercase: uppercase;
}


@font-face {
  font-family: "medium";
  src: url("../fonts/Linotype_Brewery_Medium.ttf");
}

@font-face {
  font-family: "bold";
  src: url("../fonts/Linotype_Brewery_W01_Bold.ttf");
}

@font-face {
  font-family: "regular";
  src: url("../fonts/Linotype_Brewery_W01_Regular.ttf");
}


@media screen and (max-width:600px) {

  .footer-wrapper {
    display: none;
  }

  .small-size-footer{
    margin-top: 200px;
  }
  .footer-wrapper-small {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "regular";
    font-size: 9px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    
  }

  .footer-link{
    text-decoration:none;
    color: black;
  }

  .footer-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-logo{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

}
@media screen and (min-width:600px) and (max-width:960px) {
  .footer-wrapper {
    display: none;
  }

  .small-size-footer{
    margin-top: 60px;
  }
  .footer-wrapper-small {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "bold";
    font-size: 8px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    
  }

  .footer-link{
    text-decoration:none;
    color: black;
    font-size: 8px;
  }

  .footer-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-logo{
    margin-top: 20px;
    margin-bottom: 120px;
    width: 100%;
  }

}
@media screen and (min-width:960px) and (max-width:1280px) {
  .footer-wrapper-small {
    display: none;
  }
  .footer-wrapper{
    margin-top: 150px;
  }

  .footer-link{
    text-decoration:none;
    color: black;
  }

  .footer-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us-newsletter-instagram-link-footer{
    margin: auto;
    font-family: "bold";
    font-style:var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 10px;
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: 2px;
    margin-bottom: 35px;
  }

  .footer-logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-logo{
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    margin-right: 3%;
  }
}
@media screen and (min-width:1280px) {
  .footer-wrapper-small {
    display: none;
  }
  .footer-wrapper{
    margin-top: 150px;
  }

  .footer-link{
    text-decoration:none;
    color: black;
  }

  .footer-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us-newsletter-instagram-link-footer{
    margin: auto;
    font-family: "bold";
    font-style:var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 13.2px;
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: 2px;
    margin-bottom: 35px;
  }

  .footer-logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-logo{
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    margin-right: 3%;
  }
}






