

          @media screen and (max-width:600px){

            .Spinner {
              border: 1px solid white;
              background: black;
              border-top-color: black;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              animation: spin 1s ease-in-out infinite;
              margin: 0 auto;
            }
            
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }


          @media screen and (min-width:600px) and (max-width:960px){
             .Spinner {
              border: 1px solid white;
              background: black;
              border-top-color: black;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              animation: spin 1s ease-in-out infinite;
              margin: 0 auto;
            }
            
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }


          @media screen and (min-width:960px) and (max-width:1280px){
            .Spinner {
              border: 2px solid white;
              background: black;
              border-top-color: black;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              animation: spin 1s ease-in-out infinite;
              margin: 0 auto;
            }
            
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }
          
          @media screen and (min-width:1280px) {
            .Spinner {
              border: 2px solid white;
              background: black;
              border-top-color: black;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              animation: spin 1s ease-in-out infinite;
              margin: 0 auto;
            }
            
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }