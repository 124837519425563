

@font-face {
    font-family: "regular";
    src: url("../../components/fonts/Linotype_Brewery_W01_Regular.ttf");
}



@media screen and (max-width:600px){

    .payment-container{

        font-family: "regular";
        letter-spacing: 1.5px;
        line-height: 21px;
        font-size: 10px;
        margin: 10px auto;

        
    }

    .payment-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 10px;
        
    
    }

    .payment-iyzico-info-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
    
    }
    .payment-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    }
    
    .payment-large-input{
        width: 100%;
        height: 42px;
        border-width: .9px;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: "regular";
        font-size: 10px;
        letter-spacing: 1px;
    }

    .payment-small-input{
        width: 100%;
        height: 42px;
        border-width: .9px;
        padding-left: 30px;
        font-family: "regular";
        box-sizing: border-box;
        font-size: 10px;
        letter-spacing: 1px;
    }
    
    .payment-proceed-button{
        width: 100%;
        height: 40px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: 1.5px;
        font-size: 12px;
        border: none;
        font-size: 10px;
    }

    .payment-x-small-space{
        height: 12px;
    }

    .payment-small-space{
        height: 40px;
    }

    .payment-basket-image{
        width: 100%;
    }

    .payment-shopping-bag-container{
        font-size: 10px;
        margin-top: 40px;

    }

    .payment-shopping-bag-item-details{
        width: 50%;
        height: 30px;
        margin-left: 13%;
        font-size: 10px;
    }

    .payment-card-icon{
        width: 30%;
        margin: 20px auto 10px;
    }

    .payment-carpi{
        width: 100%;
    }

}
@media screen and (min-width:600px) and (max-width:960px){
    .payment-container{

        font-family: "regular";
        letter-spacing: .97px;
        line-height: 15px;
        font-size: 11.3px;
        margin: 40px auto;

        
    }

    .payment-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
    
    }

    .payment-iyzico-info-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
    
    }
    .payment-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    }
    
    .payment-large-input{
        width: 100%;
        height: 35px;
        border-width: .9px;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: "regular";
        font-size: 10px;
    }

    .payment-small-input{
        width: 100%;
        height: 35px;
        border-width: .9px;
        padding-left: 30px;
        font-family: "regular";
        box-sizing: border-box;
        font-size: 10px;
    }
    
    .payment-proceed-button{
        width: 100%;
        height: 35px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 1.2px;
        font-size: 10px;
        border: none;
    }

    .payment-x-small-space{
        height: 12px;
    }

    .payment-small-space{
        height: 40px;
    }


    .payment-basket-image{
        width: 100%;
    }

    .payment-shopping-bag-item-details{
        width: 90%;
        height: 30px;
        margin-left: 13%;
        font-size: 10px;
    }
    .payment-card-icon{
        width: 30%;
        margin: 20px auto 10px;
    }

    .payment-shopping-bag-container{
        margin-bottom: -360px;

    } 

    .payment-shopping-bag-sum{
    }
    .payment-carpi{
        width: 100%;
    }
}
@media screen and (min-width:960px) and (max-width:1280px){

    .payment-container{

        font-family: "regular";
        letter-spacing: .97px;
        line-height: 21px;
        font-size: 11.3px;
        margin: 40px auto;

        
    }

    .payment-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 10px;
    
    }

    .payment-iyzico-info-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 13px;
        font-size: 10px;
    
    }
    .payment-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    
    }
    
    .payment-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
        font-size: 10px;
    }
    
    .payment-large-input{
        width: 100%;
        height: 35px;
        border-width: .9px;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: "regular";
        font-size: 10px;
    }

    .payment-small-input{
        width: 100%;
        height: 35px;
        border-width: .9px;
        padding-left: 30px;
        font-family: "regular";
        box-sizing: border-box;
        font-size: 10px;
    }
    
    .payment-proceed-button{
        width: 100%;
        height: 35px;
        background: black;
        color: white;
        font-family: "bold";
        letter-spacing: 1.2px;
        font-size: 9px;
        border: none;
    }

    .payment-x-small-space{
        height: 12px;
    }

    .payment-small-space{
        height: 40px;
    }


    .payment-basket-image{
        width: 100%;
    }

    .payment-shopping-bag-item-details{
        width: 90%;
        height: 30px;
        margin-left: 13%;
        font-size: 10px;
    }.payment-card-icon{
        width: 30%;
        margin: 20px auto 10px;
    }

    .payment-shopping-bag-container{
        margin-bottom: -300px;

    }

    .payment-carpi{
        width: 100%;
    }

}
@media screen and (min-width:1280px) {
    .payment-container{

        font-family: "regular";
        letter-spacing: .97px;
        line-height: 21px;
        font-size: 14px;
        margin: 30px auto;

        
    }

    .payment-contact-and-ship-to-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 13px;
    
    }

    .payment-iyzico-info-container{
        border: solid #707070;
        border-width: 1px;
        padding: 3%;
        font-family: "regular";
        letter-spacing: 1px;
        font-size: 13px;
    
    }
    .payment-changable-enabled-email-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .payment-changable-disabled-email-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .payment-changable-enabled-address-input{
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    
    }
    
    .payment-changable-disabled-address-input{
        border: none;
        width: 90%;
        font-family: "regular";
        letter-spacing: 1px ;
    }
    
    .payment-large-input{
        width: 100%;
        height: 42px;
        border-width: .9px;
        padding-left: 3.4%;
        box-sizing: border-box;
        font-family: "regular";
    }

    .payment-small-input{
        width: 100%;
        height: 42px;
        border-width: .9px;
        padding-left: 30px;
        font-family: "regular";
        box-sizing: border-box;
    }
    
    .payment-proceed-button{
        width: 100%;
        height: 42.5px;
        background: black;
        color: white;
        font-family: "regular";
        letter-spacing: .97px;
        font-size: 12px;
        border: none;
    }

    .payment-x-small-space{
        height: 12px;
    }

    .payment-small-space{
        height: 40px;
    }


    .payment-basket-image{
        width: 100%;
    }

    .payment-shopping-bag-item-details{
        width: 50%;
        height: 30px;
        margin-left: 13%;
        font-size: 13px;
    }
    .payment-card-icon{
        width: 30%;
        margin: 20px auto 10px;
    }

    .payment-shopping-bag-container{
        margin-bottom: -200px;

    }

    .payment-carpi{
        width: 100%;
    }
}

    
